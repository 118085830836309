<template>
  <div>
    <!-- Camera Sidebar -->
    <b-sidebar
      id="sidebar-camera"
      sidebar-class="sidebar-lg"
      bg-variant="white"
      shadow
      backdrop
      no-header
      right
    >
      <template #default="{ hide }">
        <!-- Header -->
        <div
          class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
        >
          <h5 class="mb-0">Cameras</h5>
          <div>
            <feather-icon
              class="ml-1 cursor-pointer"
              icon="XIcon"
              size="16"
              @click="hide"
            />
          </div>
        </div>
        <!-- Body -->
        <div v-if="cams.length > 0">
          <b-card
            v-for="camera in cams"
            v-bind:key="camera._id"
            v-bind:item="camera"
            class="mt-2 ml-1 mr-1"
          >
            <b-card-title>{{ camera.name }}</b-card-title>
            <h5>URL:</h5>
            <p>{{ camera.url }}</p>
            <h5>Image URL:</h5>
            <p>{{ camera.img }}</p>
          </b-card>
        </div>
        <div v-else class="m-2 align-text-center">
          <h4>No Cameras</h4>
        </div>
      </template>
    </b-sidebar>

    <!-- RDP Sidebar -->
    <b-sidebar
      id="sidebar-rdps"
      sidebar-class="sidebar-lg"
      bg-variant="white"
      shadow
      backdrop
      no-header
      right
    >
      <template #default="{ hide }">
        <!-- Header -->
        <div
          class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
        >
          <h5 class="mb-0">RDPs</h5>
          <div>
            <feather-icon
              class="ml-1 cursor-pointer"
              icon="XIcon"
              size="16"
              @click="hide"
            />
          </div>
        </div>
        <!-- Body -->
        <div v-if="rdps.length > 0">
          <b-card
            v-for="rdp in rdps"
            v-bind:key="rdp._id"
            v-bind:item="rdp"
            class="mt-2 ml-1 mr-1"
          >
            <b-card-title>{{ rdp.name }}</b-card-title>
            <h5>IP:</h5>
            <p>{{ rdp.ip }}</p>
          </b-card>
        </div>
        <div v-else class="m-2 align-text-center">
          <h4>No RDPs</h4>
        </div>
      </template>
    </b-sidebar>

    <!-- QuickLink Sidebar -->
    <b-sidebar
      id="sidebar-qlinks"
      sidebar-class="sidebar-lg"
      bg-variant="white"
      shadow
      backdrop
      no-header
      right
    >
      <template #default="{ hide }">
        <!-- Header -->
        <div
          class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
        >
          <h5 class="mb-0">Quick Links</h5>
          <div>
            <feather-icon
              class="ml-1 cursor-pointer"
              icon="XIcon"
              size="16"
              @click="hide"
            />
          </div>
        </div>
        <!-- Body -->
        <div v-if="qlinks.length > 0">
          <b-card
            v-for="qlink in qlinks"
            v-bind:key="qlink._id"
            v-bind:item="qlink"
            class="mt-2 ml-1 mr-1"
          >
            <b-row>
              <div class="col-3">
                <b-avatar
                  size="4rem"
                  :variant="'' + qlink.class + ''"
                  :text="qlink.shortname"
                >
                </b-avatar>
              </div>
              <div class="col-9">
                <b-card-title>{{ qlink.name }}</b-card-title>
                <h5>URL:</h5>
                <p>{{ qlink.url }}</p>
              </div>
            </b-row>
          </b-card>
        </div>
        <div v-else class="m-2 align-text-center">
          <h4>No QuickLinks</h4>
        </div>
      </template>
    </b-sidebar>

    <!-- Name Editor Sidebar -->
    <b-sidebar
      id="sidebar-editor"
      sidebar-class="sidebar-lg"
      bg-variant="white"
      shadow
      backdrop
      no-header
      right
      @hidden="resetModal"
    >
      <template #default="{ hide }">
        <!-- Header -->
        <div
          class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
        >
          <h5 class="mb-0">Edit Region Group</h5>
          <div>
            <feather-icon
              class="ml-1 cursor-pointer"
              icon="XIcon"
              size="16"
              @click="hide"
            />
          </div>
        </div>
        <!-- Body -->
        <b-form class="m-2">
          <validation-observer ref="simpleRules">
            <b-row>
              <b-col>
                <b-form-group label="Name" label-for="site-name">
                  <validation-provider
                    rules="required"
                    name="region group name"
                    v-slot="{ errors }"
                  >
                    <b-form-input
                      v-model="name"
                      :state="errors.length > 0 ? false : null"
                    />
                    <pre>{{ errors[0] }}</pre>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <!-- submit -->
              <b-col>
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  type="submit"
                  @click.prevent="validationForm"
                  variant="primary"
                  class="mr-1"
                >
                  Submit
                </b-button>
              </b-col>
            </b-row>
          </validation-observer>
        </b-form>
      </template>
    </b-sidebar>
    <!-- Name Editor -->
    <b-row>
      <b-col md="6">
        <b-card>
          <b-col>
            <h1>{{ regionGroupInstance.name }}</h1>
            <p>ID: {{ regionGroupInstance._id }}</p>
          </b-col>
          <b-row>
            <b-col md="3">
              <b-button
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                v-b-toggle.collapse-1
                variant="primary"
                @click="showEditorInSidebar()"
              >
                Edit
              </b-button>
            </b-col>
            <b-col md="3">
              <b-button
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                @click="
                  showDeleteModal(
                    regionGroupInstance._id,
                    regionGroupInstance.name
                  )
                "
                variant="danger"
              >
                Delete
              </b-button>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
      <b-col md="6">
        <b-card>
          <b-row>
            <CreateMap
              :regions="regionMaps()"
              :geoControl="true"
              :sites="siteMaps()"
              :markerControl="true"
              :draggable="false"
            >
            </CreateMap>
          </b-row>
        </b-card>
      </b-col>
    </b-row>

    <!-- site table -->
    <div class="row">
      <div class="col-12">
        <div class="vue-form-wizard mb-3 md">
          <div class="d-flex justify-content-between flex-wrap mr-1 ml-1">
            <div class="d-flex align-items-center mb-1 mt-1">
              <span class="">Show</span>
              <b-form-select
                v-model="pageLength"
                :options="['5', '10', '20', '50', '100']"
                class="mx-1"
              />
            </div>
            <div>
              <div class="custom-search d-flex justify-content-right mt-1 mb-1">
                <div class="d-flex align-items-center">
                  <span class="mr-1">Search</span>
                  <b-form-input
                    v-model="searchTerm"
                    placeholder="Search"
                    type="text"
                    class="d-inline-block"
                  />
                </div>
              </div>
            </div>
          </div>

          <vue-good-table
            :columns="columns"
            :rows="rows"
            :search-options="{
              enabled: true,
              externalQuery: searchTerm
            }"
            :pagination-options="{
              enabled: true,
              perPage: pageLength
            }"
          >
            <template slot="table-row" slot-scope="props">
              <!-- Column: Action -->
              <span v-if="props.column.field === 'actions'">
                <span>
                  <b-dropdown
                    variant="link"
                    toggle-class="text-decoration-none"
                    no-caret
                  >
                    <template v-slot:button-content>
                      <feather-icon
                        icon="MoreVerticalIcon"
                        size="16"
                        class="text-body align-middle mr-25 table-icon"
                      />
                    </template>
                    <b-dropdown-item
                      @click="handleRouteToEditor(props.row.siteID)"
                    >
                      <feather-icon icon="Edit2Icon" class="mr-50 table-icon" />
                      <span>Edit</span>
                    </b-dropdown-item>
                    <b-dropdown-item
                      @click="
                        showDeleteSiteModal(props.row.siteID, props.row.name)
                      "
                    >
                      <feather-icon icon="TrashIcon" class="mr-50 table-icon" />
                      <span>Delete</span>
                    </b-dropdown-item>
                  </b-dropdown>
                </span>
              </span>
              <!-- Column: Name -->
              <span v-else-if="props.column.field === 'name'">
                <div
                  @click="handleRouteToEditor(props.row.siteID)"
                  class="link"
                >
                  {{ props.row.name }}
                </div>
              </span>
              <!-- Column: Cameras -->
              <span v-else-if="props.column.field === 'cameras'">
                {{ props.row.cameras.length }}
                <eye-icon
                  v-if="props.row.cameras.length > 0"
                  @click="showCamerasInSidebar(props.row.cameras)"
                  class="table-icon"
                />
              </span>
              <!-- Column: RDPs -->
              <span v-else-if="props.column.field === 'rdps'">
                {{ props.row.rdps.length }}
                <eye-icon
                  v-if="props.row.rdps.length > 0"
                  @click="showRDPsInSidebar(props.row.rdps)"
                  class="table-icon"
                />
              </span>
              <!-- Column: QuickLinks -->
              <span v-else-if="props.column.field === 'quicklinks'">
                {{ props.row.quicklinks.length }}
                <eye-icon
                  v-if="props.row.quicklinks.length > 0"
                  @click="showQuickLinksInSidebar(props.row.quicklinks)"
                  class="table-icon"
                />
              </span>
              <!-- Column: Status -->
              <span v-else-if="props.column.field === 'status'">
                <b-badge :variant="statusVariant(props.row.status)">
                  {{ props.row.status }}
                </b-badge>
              </span>
              <span v-else>
                {{ props.formattedRow[props.column.field] }}
              </span>
            </template>

            <!-- Pagination -->
            <template slot="pagination-bottom" slot-scope="props">
              <div class="d-flex justify-content-between flex-wrap">
                <div class="d-flex align-items-center mb-0 mt-1">
                  <span class="text-nowrap mb-1 ml-1"> Show</span>

                  <b-form-select
                    v-model="pageLength"
                    :options="['5', '10', '20', '50', '100']"
                    class="mx-1 mb-1"
                    @input="
                      (value) => props.perPageChanged({ currentPerPage: value })
                    "
                  />
                </div>
                <div>
                  <b-pagination
                    :value="1"
                    :total-rows="props.total"
                    :per-page="pageLength"
                    first-number
                    last-number
                    align="right"
                    prev-class="prev-item"
                    next-class="next-item"
                    class="mt-1 mb-1 mr-1"
                    @input="
                      (value) => props.pageChanged({ currentPage: value })
                    "
                  >
                    <template #prev-text>
                      <feather-icon icon="ChevronLeftIcon" size="18" />
                    </template>
                    <template #next-text>
                      <feather-icon icon="ChevronRightIcon" size="18" />
                    </template>
                  </b-pagination>
                </div>
              </div>
            </template>
          </vue-good-table>
        </div>
      </div>
    </div>

    <!-- Delete Site Modal -->
    <b-modal
      id="site-modal-prevent-closing"
      ref="site-modal"
      title="Delete Site"
      ok-title="Delete"
      @show="resetSiteModal"
      @hidden="resetSiteModal"
      @ok="handleSiteOk"
    >
      <h6>Warning</h6>
      <p>Please enter in the name of the Site to confirm deletion. <br /></p>
      <span
        >Name:
        <h6>{{ siteToDelete.name }}</h6></span
      >
      <form ref="form" @submit.stop.prevent="handleSiteSubmit">
        <b-form-group
          label="Name"
          label-for="name-input"
          invalid-feedback="Name Must Match Region Group Name"
          :state="nameStateSite"
        >
          <b-form-input
            id="name-input"
            v-model="nameSite"
            :state="nameStateSite"
            required
          ></b-form-input>
        </b-form-group>
      </form>
    </b-modal>

    <!-- Deletion Modal -->
    <b-modal
      id="modal-prevent-closing"
      ref="modal"
      title="Delete Region Group"
      ok-title="Delete"
      @show="resetModal"
      @hidden="resetModal"
      @ok="handleOk"
    >
      <h6>Warning</h6>
      <p>
        Deleting this Region Group will delete all Sites attached to it.<br /><br />
        Please enter in the name of the Region Group to confirm. <br />
      </p>
      <span
        >Name:
        <h6>{{ groupToDelete.name }}</h6></span
      >
      <form ref="form" @submit.stop.prevent="handleSubmit">
        <b-form-group
          label="Name"
          label-for="name-input"
          invalid-feedback="Name Must Match Region Group Name"
          :state="nameState"
        >
          <b-form-input
            id="name-input"
            v-model="name"
            :state="nameState"
            required
          ></b-form-input>
        </b-form-group>
      </form>
    </b-modal>
  </div>
</template>

<script>
import {
  BPagination,
  BFormInput,
  BFormSelect,
  BDropdown,
  BModal,
  BAvatar,
  BSidebar,
  BCard,
  BCardBody,
  BCardTitle,
  BDropdownItem,
  BCollapse,
  BBadge,
  BFormGroup,
  BButton,
  VBToggle,
  BForm,
  BRow,
  BCol,
  BDropdownGroup,
  BFormCheckbox
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import CreateMap from '@/components/maps/MapComponent.vue'
// Import Icons
import { EyeIcon, MoreVerticalIcon } from 'vue-feather-icons'
// import api
import { regionGroup } from '@/services/regionGroup.service.js'
import { site } from '@/services/site.service.js'
// import Mixins
import { heightTransition } from '@core/mixins/ui/transition'
import prepareQueryParamsMixin from '@/mixins/prepareQueryParamsMixin'
import prepareFetchParamsMixin from '@/mixins/prepareFetchParamsMixin'

// import validation for editors
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'

// Import Table
import { VueGoodTable } from 'vue-good-table'
import 'vue-good-table/dist/vue-good-table.css'

// Import Icons
import FeatherIcon from '../../@core/components/feather-icon/FeatherIcon.vue'

// Declare component
export default {
  name: 'RegionEditor',
  prop: ['id'],
  mixins: [prepareQueryParamsMixin, prepareFetchParamsMixin],
  components: {
    ValidationProvider,
    ValidationObserver,
    BAvatar,
    BCollapse,
    BButton,
    VBToggle,
    CreateMap,
    BModal,
    BBadge,
    BPagination,
    BForm,
    BFormInput,
    BFormSelect,
    BFormGroup,
    BDropdown,
    BDropdownItem,
    EyeIcon,
    MoreVerticalIcon,
    VueGoodTable,
    BSidebar,
    BCard,
    BCardBody,
    BCardTitle,
    BRow,
    BCol,
    BDropdownGroup,
    BFormCheckbox
  },
  directives: {
    FeatherIcon,
    'b-toggle': VBToggle,
    Ripple
  },
  mixins: [heightTransition],
  data() {
    return {
      pageLength: 5,
      columns: [
        {
          label: 'Site Name',
          field: 'name'
        },
        {
          label: 'Region',
          field: 'groupName'
        },
        {
          label: 'Cameras',
          field: 'cameras'
        },
        {
          label: 'RDPS',
          field: 'rdps'
        },
        {
          label: 'Quick Links',
          field: 'quicklinks'
        },
        {
          label: 'Status',
          field: 'status'
        },
        {
          label: 'Actions',
          field: 'actions'
        }
      ],
      rows: [],
      status: [
        {
          1: 'Published',
          2: 'Unpublished'
        },
        {
          1: 'light-success',
          2: 'light-warning'
        }
      ],
      searchTerm: '',
      cams: [],
      rdps: [],
      qlinks: [],
      groupToDelete: {
        name: '',
        id: ''
      },
      name: '',
      geoJSON: {},
      nameState: null,
      regionGroupInstance: {},
      coordinates: [],
      nameSite: '',
      nameStateSite: '',
      siteToDelete: {
        name: '',
        id: ''
      }
    }
  },
  mounted() {
    document.onreadystatechange = () => {
      if (document.readyState == 'complete') {
        window.dispatchEvent(new Event('resize'))
      }
    }
  },
  methods: {
    // //////////////////////////////////// Site Deletion Modal Controls ////////////////////////////////////////////
    checkFormValidity() {
      const valid = this.nameSite === this.siteToDelete.name ? true : false
      this.nameStateSite = valid
      return valid
    },
    resetSiteModal() {
      this.nameSite = ''
      this.nameStateSite = null
    },
    handleSiteOk(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault()
      // Trigger submit handler
      this.handleSiteSubmit()
    },
    handleSiteSubmit() {
      // Exit when the form isn't valid
      if (!this.checkFormValidity()) {
        return
      }

      // Push the name to submitted names
      this.deleteSite(this.siteToDelete.id)
      // Hide the modal manually
      this.$nextTick(() => {
        this.$bvModal.hide('site-modal-prevent-closing')
      })
    },
    regionMaps() {
      let mapReturn = [this.regionGroupInstance]
      return mapReturn
    },
    siteMaps() {
      return this.rows
    },
    showDeleteSiteModal(id, name) {
      this.siteToDelete.name = name
      this.siteToDelete.id = id
      this.$bvModal.show('site-modal-prevent-closing')
    },

    async deleteSite(id) {
      site
        .deleteSite({ siteID: id })
        .then((res) => {
          this.getSitesByGroupId(this.$attrs.id)
          window.Bus.instance.$emit('pageSuccess', {
            header: 'SUCCESS',
            detail: 'Successfully Deleted Site'
          })
        })
        .catch((error) => {
          window.Bus.instance.$emit('pageDanger', {
            header: 'ERROR',
            detail: error.message
          })
        })
    },

    //////////////////////////////////////////////////////////////////////////

    handleRouteToEditor(id) {
      this.$router.push('/siteeditor/' + id)
    },
    validationForm() {
      this.$refs.simpleRules.validate().then((success) => {
        if (success) {
          // eslint-disable-next-line
          let updatedGroup = {
            name: this.name,
            _id: this.regionGroupInstance._id
          }
          regionGroup
            .updateRegionGroup(updatedGroup)
            .then((res) => {
              this.regionGroupInstance.name = this.name

              window.Bus.instance.$emit('pageSuccess', {
                header: 'Success',
                detail: 'Region Group Successfully Updated'
              })
              this.$root.$emit('bv::toggle::collapse', 'sidebar-editor')
            })
            .catch((error) => {
              window.Bus.instance.$emit('pageDanger', {
                header: 'Error',
                detail: error.message
              })
            })
        }
      })
    },
    showEditorInSidebar() {
      this.name = this.regionGroupInstance.name
      this.$root.$emit('bv::toggle::collapse', 'sidebar-editor')
    },
    showCamerasInSidebar(cameras) {
      this.cams = cameras
      this.$root.$emit('bv::toggle::collapse', 'sidebar-camera')
    },
    showRDPsInSidebar(rdps) {
      this.rdps = rdps
      this.$root.$emit('bv::toggle::collapse', 'sidebar-rdps')
    },
    showQuickLinksInSidebar(qlinks) {
      this.qlinks = qlinks
      this.$root.$emit('bv::toggle::collapse', 'sidebar-qlinks')
    },
    checkFormValidity() {
      const valid = this.name === this.groupToDelete.name ? true : false
      this.nameState = valid
      return valid
    },
    resetModal() {
      this.name = ''
      this.nameState = null
    },
    handleOk(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault()
      // Trigger submit handler
      this.handleSubmit()
    },

    handleSubmit() {
      // Exit when the form isn't valid
      if (!this.checkFormValidity()) {
        return
      }
      // Push the name to submitted names
      this.deleteRegionGroup(this.groupToDelete.id)
      // Hide the modal manually
      this.$nextTick(() => {
        this.$bvModal.hide('modal-prevent-closing')
      })
    },

    showDeleteModal(id, name) {
      this.groupToDelete.name = name
      this.groupToDelete.id = id
      this.$bvModal.show('modal-prevent-closing')
    },

    async deleteRegionGroup(id) {
      regionGroup
        .deleteRegionGroup({ groupId: id })
        .then((res) => {
          window.Bus.instance.$emit('pageSuccess', {
            header: 'SUCCESS',
            detail: res.message
          })
          this.$router.push('/regionmanager')
        })
        .catch((error) => {
          window.Bus.instance.$emit('pageDanger', {
            header: 'ERROR',
            detail: error.message
          })
        })
    },

    async getSitesByGroupId(Id) {
      try {
        site
          .getAllSitesByRegionGroup(Id)
          .then((data) => {
            this.rows = data.data.data
          })
          .catch((error) => {
            window.Bus.instance.$emit('pageDanger', {
              header: 'ERROR',
              detail: error.response.data
                ? error.response.data.error
                : error.response.statusText
            })
          })
      } catch (error) {
        window.Bus.instance.$emit('pageDanger', {
          header: 'ERROR',
          detail: error.response.data
            ? error.response.data.error
            : error.response.statusText
        })
      }
    },
    getRegionGroup(Id) {
      try {
        regionGroup
          .getRegionGroupById(Id)
          .then((data) => {
            this.regionGroupInstance = data.data
          })
          .catch((error) => {
            window.Bus.instance.$emit('pageDanger', {
              header: 'ERROR',
              detail: error.response.data
                ? error.response.data.error
                : error.response.statusText
            })
          })
      } catch (error) {
        window.Bus.instance.$emit('pageDanger', {
          header: 'ERROR',
          detail: error.response.data
            ? error.response.data.error
            : error.response.statusText
        })
      }
    }
  },
  computed: {
    statusVariant() {
      const statusColor = {
        /* eslint-disable key-spacing */
        Published: 'light-success',
        Unpublished: 'light-warning'
        /* eslint-enable key-spacing */
      }
      return (status) => statusColor[status]
    }
  },
  created: function () {},
  async mounted() {
    this.getRegionGroup(this.$attrs.id)
    this.getSitesByGroupId(this.$attrs.id)
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-good-table.scss';
@import '@core/scss/vue/libs/vue-wizard.scss';
.table-icon {
  color: $primary;
  //height: 16px !important;
  //width: 16px !important;
}
.trash-icon {
  color: $danger !important;
}
.align-text-center {
  text-align: center;
}
.placeholder {
  padding: 1rem;
  max-height: 100%;
  max-width: 100%;
}
.link:hover {
  color: $primary;
  cursor: pointer;
  text-decoration: underline;
}
/* The Close Button */
.close {
  margin-right: -3px !important;
  margin-top: -3px !important;
}

.map-container {
  height: 40vh;
  width: 100%;
}
</style>
